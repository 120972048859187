<template>
  <div class="app-container">
    <CrudTable entity="HomePageBlock" :columns="columns" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        'name',
        'position',
        {
          field: 'onlineYN',
          label: 'common.onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        },
        {
          field: 'countryCode',
          label: 'common.country'
        },
        {
          field: 'blockType',
          label: 'homepageBlock.blockType',
          minWidth: '100px',
          sortable: false,
          formatter: this.formatBlockTypeOption,
          filter: {
            type: 'select',
            options: this.$store.getters['option/loadedOptions']('blockType'),
            valueField: 'code',
            labelField: 'description'
          }
        },
        {
          field: 'restaurantName'
        }
      ]
    };
  },
  methods: {
    formatBlockTypeOption(row, column, cellValue) {
      const option =
        cellValue !== null &&
        this.$store.getters['option/loadedOptions']('blockType').find(item => item.code === cellValue);
      return option && option.description;
    }
  }
};
</script>

<style scoped></style>
